{
  "button": {
    "back": "Indietro",
    "cancel": "Annulla",
    "confirm": "Confermare",
    "fullscreen_enter": "Entra a schermo intero",
    "fullscreen_exit": "Esci dalla modalità schermo intero",
    "refresh": "ricaricare",
    "toggle_dark": "Attiva/disattiva modalità scura",
    "toggle_langs": "Cambia lingua",
    "remove": "Rimuovere",
    "clear_all": "Cancella tutto",
    "delete": "Elimina",
    "edit": "Modificare",
    "buy_now": "Acquista ora",
    "enter_another_card": "Inserisci un'altra carta",
    "try_again": "Riprova",
    "enter_another_code": "Inserisci un altro codice",
    "submit_code": "Invia codice",
    "download": "Scarica",
    "no": "No",
    "go_to_homepage": "Vai alla pagina principale",
    "slide_back": "Diapositiva precedente",
    "slide_forward": "Prossima diapositiva",
    "exit": "Uscita",
    "full": "Pieno",
    "regular": "Regolare",
    "rename": "Rinominare",
    "get": "Ottenere"
  },
  "common": {
    "loading": "Caricamento in corso...",
    "loading_failed": "Caricamento fallito",
    "empty_search": "Nessun risultato trovato con un titolo \"{searchValue}\".",
    "empty_list": "L'elenco è vuoto.\nTorna più tardi.",
    "data_not_found": "Dati non trovati o server che non risponde. Riprova più tardi.",
    "empty_events_list": "Non ci sono ancora eventi",
    "get_mobile_app": "Ottieni la migliore esperienza \n{appName}"
  },
  "not-found": "Non trovato",
  "locales": {
    "en": "English",
    "de": "German",
    "es": "Spanish",
    "uk-UA": "Ukrainian",
    "pt": "Portuguese",
    "pl": "Polish",
    "fr": "French",
    "he": "Hebrew",
    "it": "Italian",
    "ko": "Korean",
    "zh": "Chinese",
    "bs": "Lokalni",
    "title": "Scegli la lingua del sito web",
    "ca": "Catalan"
  },
  "baseHeader": {
    "menu": {
      "account": "Il tuo account",
      "language": "Lingua: {currentLanguage}",
      "logout": "Disconnettersi",
      "manage_following": "Gestisci il seguito",
      "select_language": "Seleziona la lingua",
      "selected_videos": "Video selezionati",
      "my_clips_and_highlights": "Le mie clip/punti salienti"
    }
  },
  "labels": {
    "sign_in": "Registrazione",
    "sign_up": "Iscrizione",
    "continue": "Continua",
    "email": "E-mail",
    "forgot_password": "Ha dimenticato la password",
    "password": "Parola d'ordine",
    "date_added": "Data aggiunta:",
    "highlights": "Evidenziare | Punti salienti",
    "latest": "Ultimo",
    "live": "Abitare",
    "password_new": "nuova password",
    "password_old": "vecchia password",
    "password_repeat": "Ripeti la password",
    "save": "Salva",
    "see_all": "Vedi tutto >",
    "upcoming": "In arrivo",
    "update": "Aggiornare",
    "country": "Paese",
    "phone": "Telefono",
    "search": "Ricerca",
    "load_more": "Carica di più",
    "follow": "Seguire",
    "following": "Seguente",
    "total": "Totale",
    "all": "Tutto",
    "sport_type": "Tipo sportivo",
    "full_name": "Nome e cognome",
    "newest": "Il più recente",
    "oldest": "Il più antico",
    "modal_labels": {
      "delete_clip": "Sei sicuro di voler rimuovere questa clip?",
      "delete_highlight": "Sei sicuro di voler rimuovere questa evidenziazione?",
      "delete_profile": "Sei sicuro di voler eliminare il tuo profilo?",
      "delete_tag": "Sei sicuro di voler rimuovere questi tag? |\nSei sicuro di voler rimuovere questo tag?"
    },
    "active": "Attivo",
    "submit": "Invia",
    "purchase": "Acquistare",
    "confirm_new_password": "Conferma la nuova password",
    "enter_new_password": "Inserire una nuova password",
    "dark": "Scuro",
    "light": "Luce",
    "powered_by": "Offerto da",
    "policy": "politica sulla riservatezza",
    "terms": "Termini di servizio",
    "next": "Prossimo",
    "search_input_placeholder": "Cerca scuole, club, campionati, ecc.",
    "skip": "Saltare",
    "amount": "Quantità",
    "date": "Data",
    "type": "Tipo",
    "continue_with": "Continua con {provider}",
    "or": "o",
    "anytime": "In qualsiasi momento",
    "event_date": "Data dell'evento",
    "past_30_days": "Passati i 30 giorni",
    "past_7_days": "Passati 7 giorni",
    "past_90_days": "Passati 90 giorni",
    "today": "In data odierna",
    "yesterday": "Ieri",
    "all_results": "Tutti i risultati",
    "events": "Eventi",
    "views": "nessuna visualizzazione |\n{conteggio} visualizza |\n{count} visualizzazioni",
    "clip": "clip |\nClip",
    "tag": "Etichetta |\nTag",
    "game_videos": "Video di giochi",
    "bookmark_add": "Aggiungi a selezionati",
    "bookmark_remove": "Rimuovi da selezionato",
    "share": "Condividere",
    "days": "Giorni | \ngiorno | \ngiorni",
    "hours": "Ore | \nora | \nore",
    "minutes": "Minuti | \nminuta | \nminuti",
    "seconds": "Secondi | \nseconda | \nsecondi",
    "latest_events": "Ultimi eventi",
    "latest_highlights": "Ultimi momenti salienti",
    "statistics": "Statistiche",
    "commerce_category_labels": {
      "free": "Gratuito",
      "member": "Membro",
      "ppv": "Pay per view",
      "subscription": "Sottoscrizione",
      "rental": "Noleggio",
      "premium": "Premium"
    },
    "lock_event_message": {
      "member": "Accedi per guardare la partita",
      "geo_blocking": "Siamo spiacenti, ma il video che stai tentando di riprodurre è limitato dalla tua regione",
      "event_restricted": "Questo evento è limitato per la tua località",
      "premium": "Iscriviti per guardare",
      "subscription": ""
    },
    "main": "Principale",
    "game_highlights": "Momenti salienti del gioco",
    "player_highlights": "Punti salienti del giocatore",
    "watch_full_game": "Guarda il gioco completo",
    "your_clips_highlight": "Le tue clip/punti salienti",
    "create_highlight": "Crea punti salienti",
    "access_code": "Inserisci il codice di accesso",
    "ppv_price": "Guarda da {price}",
    "access_time": "Avrai accesso a",
    "full_access": "Accesso completo",
    "limited_access": "Accesso limitato",
    "ppv_tickets": "Biglietti PPV",
    "subscriptions": "Abbonamenti",
    "release_date": "Data di rilascio",
    "months": "Mesi | \nmese | \nmesi",
    "weeks": "Settimane | \nsettimana | \nsettimane",
    "years": "Anni | \nanno | \nanni",
    "change_product": "Cambia prodotto",
    "go_to_event": "Vai all'evento",
    "go_to_account": "Vai al conto",
    "go_to_main_page": "Vai alla pagina principale",
    "name": "Nome",
    "update_card": "Aggiorna i dati della tua carta",
    "page_not_found": "pagina non trovata",
    "inactive": "Inattivo",
    "my_club": "Il mio Circolo",
    "select_your_club": "Seleziona il tuo club",
    "add_to_selected": "Aggiungi a selezionati",
    "remove_from_selected": "Rimuovi da selezionati",
    "password_not_match": "Le password non corrispondono",
    "search_by_sport_type": "Cerca per tipo di sport",
    "videos": "Video",
    "category": "Categoria",
    "reset_password": "Inserisci la tua nuova password",
    "min_8_characters": "Minimo 8 caratteri",
    "league": "Lega",
    "here": "Qui",
    "suggested": "Suggerito",
    "enter_old_password": "Inserisci la vecchia password",
    "less": "Meno",
    "more": "Di più",
    "durationRenew": "Durata: verrà rinnovato {duration} {units} dopo l'acquisto | \nDurata: verrà rinnovato il {duration}",
    "duration": "Durata: scade {duration} {units} dopo l'acquisto | \nDurata: scade {duration}",
    "team": "Squadra",
    "team_1_logo": "Logo della squadra 1",
    "team_2_logo": "Logo della squadra 2",
    "stats": "Statistiche",
    "gameType_labels": {
      "game": "Gioco",
      "other": "Altro",
      "practice": "Pratica",
      "training": "formazione"
    },
    "fullName": "Nome e cognome",
    "archive": "Archivio",
    "panoramic_mode": "Sei in modalità panoramica",
    "postal_code": "Codice Postale",
    "search_event_league_team": "Cerca un evento, un campionato o una squadra",
    "back_to_site": "Torna al sito",
    "done": "Fatto",
    "search_teams": "Cerca squadre",
    "game_recap": "Riepilogo del gioco",
    "play_by_play": "Gioco dopo gioco",
    "your_clips": "Le tue clip",
    "gender": "Genere",
    "gender_labels": {
      "female": "Femmina",
      "male": "Maschio",
      "mixed": "Co-ed"
    }
  },
  "pages": {
    "auth_sign_up": {
      "enter_your_details_below": "Inserisci i tuoi dati qui sotto",
      "password_updated_successuly_text": "La tua password è stata aggiornata con successo",
      "already_have_account": "Hai già un account?",
      "follow_category_header": "Seleziona le categorie che vuoi seguire",
      "back_to_all_teams": "Ritorno a tutte le squadre",
      "follow_teams_header": "Seleziona le squadre che vuoi seguire",
      "back_to_all_categories": "Torna a tutte le categorie"
    },
    "account": {
      "account_delete_button": "Eliminare l'account",
      "tab_billing_title": "Informazioni di fatturazione",
      "tab_devices_title": "Dispositivi",
      "tab_general_title": "Generale",
      "tab_password_title": "Parola d'ordine",
      "tab_subscription_title": "Sottoscrizione",
      "account_delete_confirm": "Sei sicuro di voler eliminare il tuo profilo?",
      "account_delete_description": "Questa azione non può essere annullata.",
      "tab_ppv_access_text": "Il biglietto PPV dà accesso a",
      "tab_ppv_no_ppv": "Non hai biglietti PPV. \nVai alla pagina dell'evento per acquistare un biglietto.",
      "tab_ppv_title": "Biglietti PPV",
      "account_delete_successfully": "Il tuo account è stato eliminato con successo!",
      "account_update_successfully": "Il tuo account è stato aggiornato con successo!"
    },
    "auth_password": {
      "forgot_password_help_text": "Ti aiuteremo a ripristinarlo e tornare in pista.",
      "back_to_sign_in": "Torna per accedere",
      "send_reset_link": "Invia collegamento di ripristino",
      "set_new_password": "Imposta nuova password",
      "forgot_password_success_text": "Un'email con le istruzioni su come reimpostare la password è stata inviata a {email}. \nSe non ricevi l'e-mail entro pochi minuti, fai clic sul pulsante Reinvia istruzioni in basso.",
      "resend_instruction": "Invia nuovamente le istruzioni"
    },
    "clubs": {
      "manage_following": "Gestisci il seguito",
      "choose_your_club": "Scegli il tuo circolo",
      "choose_your_club_message": "Scegli il club a cui sarai associato"
    },
    "auth_login": {
      "device_no_longer_allowed_error": "Il tuo dispositivo attuale non è più nell'elenco dei dispositivi consentiti. Per favore esegui l'accesso di nuovo."
    },
    "auth_login_limit": {
      "device_limit_reached": "Limite dispositivi raggiunto",
      "remove_device_to_login": "Si prega di rimuoverne uno per accedere",
      "current_device": "Dispositivo attuale"
    },
    "account_billing": {
      "card_cvv": "CVC/CVV",
      "card_expiry_date": "Data di scadenza",
      "card_holder_name": "nome sulla carta",
      "card_number": "Numero di carta",
      "button_back_to_billing_info": "Torna alle informazioni di fatturazione",
      "payment_history_empty_text": "La cronologia dei pagamenti è vuota",
      "payment_history_title": "Storico dei pagamenti",
      "alert_credit_card_save_success": "I dati della tua carta di credito sono stati salvati correttamente",
      "alert_credit_card_update_success": "I dati della tua carta di credito sono stati aggiornati correttamente",
      "label_modal_confirm_credit_card_delete": "Una volta rimossa la carta di credito, anche l'abbonamento verrà annullato. \nIl contenuto è ancora disponibile per te fino alla data di fine del piano. \nSei sicuro di voler rimuovere la carta di credito?",
      "alert_credit_card_delete_success": "La tua carta di fatturazione è stata eliminata con successo",
      "payment_in_progress": "Pagamento in corso",
      "payment_in_progress_message": "Ti preghiamo di non chiudere questa pagina finché il pagamento non sarà stato elaborato."
    },
    "account_subscription": {
      "active_subscription": "Abbonamento attivo",
      "button_subscribe_now": "Iscriviti ora",
      "button_subscription_cancel": "Annullare l'iscrizione",
      "subscription_due_date": "Scadenza",
      "subscription_expiration_date": "Scaduto da",
      "subscription_last_payment_date": "Ultima data di pagamento",
      "subscription_next_charge_date": "Prossima carica",
      "button_back_to_my_subscription": "Torna al mio abbonamento",
      "or_select_plan": "o seleziona il tuo piano",
      "redeem_access_code_label": "Riscatta il tuo codice di accesso qui",
      "alert_access_code_redeem_not_found": "Il codice di accesso richiesto non esiste",
      "alert_access_code_redeemed_success": "Codice di accesso riscattato con successo",
      "alert_subscription_cancel_success": "Abbonamento annullato con successo",
      "label_plan_amount": "Importo del piano",
      "label_taxes": "Le tasse",
      "label_total_billed": "Totale fatturato",
      "tab_all_subscriptions": "Tutti gli abbonamenti",
      "tab_my_subscriptions": "le mie sottoscrizioni",
      "alert_access_code_redeem_code_used": "Codice di accesso richiesto già utilizzato",
      "label_full_access": "Accesso completo",
      "label_full_access_description": "Pieno accesso a tutti i contenuti del sito web",
      "label_limited_access": "Accesso limitato",
      "label_limited_access_description": "Accesso limitato a tutti i contenuti del sito web",
      "subscription_expires": "L'abbonamento scade il giorno {date}",
      "payment_details": "{title}: Dettagli sul pagamento",
      "no_subscriptions_text": "Non ci sono abbonamenti attivi nell'elenco.\n\nTorna più tardi",
      "redeem_access_code_title": "Riscatta il tuo codice di accesso",
      "button_back_to_my_tickets": "Torniamo ai miei biglietti",
      "list_subscriptions_empty": "L'elenco degli abbonamenti disponibili è vuoto. \nTorna più tardi",
      "button_subscription_keep": "Mantieni l'abbonamento",
      "cancel_subscription_modal_subtitle": "Il tuo abbonamento verrà annullato alla fine del periodo di fatturazione in data {date}. \nPuoi cambiare idea in qualsiasi momento prima di questa data.",
      "cancel_subscription_modal_title": "Sei sicuro di voler cancellare il tuo abbonamento?",
      "checkbox_apply_as_new_credit_card": "Salva i dettagli della carta",
      "renew_subscription_expires": "L'abbonamento è ricorrente. \nProssima data di addebito {date}"
    },
    "videos": {
      "last_viewed": "Ultimo visto",
      "user_interesting": "Potresti essere interessato",
      "description_no_items": "Questo è il posto giusto per i tuoi video preferiti.\nSalva, rivedi e condividi!",
      "title_no_items": "Aggiungi alcuni video a selezionati"
    },
    "clips": {
      "title_no_clips": "Non hai ancora clip.\nVai al video e crea la tua prima clip!",
      "title_no_highlights": "Non hai ancora punti salienti.\nVai al video e crea i tuoi primi momenti salienti!",
      "title_no_items_with_selected_filter": "Nessun articolo trovato con questo filtro"
    },
    "auth_sign_in": {
      "welcome_message": "Ben tornato",
      "new_to": "Nuovo per",
      "auth_terms": "Continuando, accetti i nostri {terms_of_service} e {privacy_policy}",
      "sign_in_with_provider": "Accedi con le tue credenziali di {provider}"
    },
    "account_password": {
      "alert_password_update_success": "La tua password è stata cambiata con successo"
    },
    "league": {
      "all_rounds": "Tutti i round"
    },
    "events": {
      "event_start_date": "L'evento avrà inizio {date}",
      "failed_statistics": "Impossibile caricare le statistiche relative a questo evento. Riprova più tardi.",
      "modal_share_with_time": "Inizia alle {time}",
      "modal_share_title": "Condividi questo video",
      "tabs": {
        "event_tags": {
          "create_tag": "Crea etichetta",
          "title_no_tags_not_logged_in": "Non ci sono ancora tag nell'elenco, effettua il login per creare nuovi tag",
          "choose_player_color": "Inserisci un giocatore '",
          "choose_tag": "Scegli il tipo di tag",
          "make_tag_global": "Rendi il tag globale",
          "name_the_tag": "Assegna un nome al tag",
          "tag_creating_successfully_text": "Il tag è stato creato con successo",
          "title_enabled_tagging": "Non ci sono tag nell'elenco",
          "update_tag": "Aggiorna tag",
          "tag_updating_successfully_text": "Il tag è stato aggiornato correttamente",
          "filters": {
            "by_player_number": "Giocatore '",
            "by_type": "Per tipo",
            "clear_all_btn": "Cancella tutto |\nCancella tutto ({count}) |\nCancella tutto ({count})",
            "no_tags": "Nessun tag corrispondente al filtro selezionato",
            "popup_title": "Filtro",
            "search_input_placeholder": "Cerca tag specifici",
            "show_results_btn": "Mostra i risultati",
            "select_all": "Seleziona tutto | \nSeleziona tutto ({count}) | \nSeleziona tutto ({count})",
            "by_team": "Per squadra"
          },
          "tag_deleting_successfully_text": "Il tag è stato eliminato con successo",
          "is_external_content": "Questo è un contenuto esterno, non puoi creare un tag",
          "title_no_tags": "Nessun tag è stato ancora creato",
          "pause_all_btn": "Metti in pausa tutto",
          "play_all_btn": "Giocare tutto",
          "choose_team": "Scegli una squadra"
        },
        "event_videos": {
          "create_clip": "Crea clip",
          "title_no_clips_not_logged_in": "Non ci sono ancora clip nell'elenco, effettua il login per creare nuove clip",
          "name_the_clip": "Assegna un nome alla clip",
          "clip_creating_successfully_text": "La tua clip sarà presto pronta",
          "title_enabled_clipping": "Non ci sono clip nell'elenco",
          "title_no_clips": "Non è stato ancora creato alcun clip",
          "clip_deleted_successfully_text": "La clip è stata eliminata con successo",
          "rename_clip": "Rinomina clip",
          "clip_updating_successfully_text": "La clip è stata aggiornata con successo"
        },
        "event_stats": {
          "empty_stats_data": "I dati statistici sono vuoti",
          "game_board_title": "Statistiche di gioco",
          "score_board_title": "Punto"
        }
      },
      "viewing_conditions": "Scegli le condizioni di visualizzazione",
      "appreciation": "Grazie!",
      "success_purchased": "Hai acquistato con successo {subscription}",
      "failed_purchased_message": "Riprova o inserisci un'altra carta.",
      "purchased_declined": "Il pagamento è stato rifiutato",
      "watching_event_by_text": "Stai guardando questo evento di",
      "failed_code_applied_message": "Riprova o inserisci un altro codice.",
      "failed_code_applied_title": "Codice non VALIDO",
      "success_code_applied": "Codice {title} applicato correttamente",
      "submit_reedem_code_input_placeholder": "Inserisci qui il tuo codice di accesso",
      "failed_already_used_code": "Questo codice è già stato utilizzato",
      "event_will_start_in_a_few_min": "L'evento inizierà automaticamente tra pochi minuti...",
      "player_errors": {},
      "game_video_login_message": "Accedi per visualizzare i video dei giochi",
      "tags_login_message": "Accedi per visualizzare i tag",
      "success_purchased_secondary": "Il tuo acquisto è andato a buon fine",
      "time_till_kick_off": "Tempo fino al calcio d'inizio:",
      "failed_purchased_message_2": "Riprova o utilizza un altro metodo di pagamento."
    },
    "club": {
      "favorite_club_creating_successfully_text": "Il tuo club è stato scelto con successo"
    },
    "categories": {
      "no_following_category_for_logged_user": "Non stai seguendo nulla al momento. \nFai clic {here} per rimanere aggiornato sui tuoi eventi preferiti",
      "no_following_category_for_unlogged_user": "Effettua il {login} per rimanere aggiornato sui tuoi eventi preferiti"
    }
  },
  "sport_types": {
    "americanFootball": "Football americano",
    "beachvolleyball": "Beach volley",
    "fieldhockey": "Hockey su prato",
    "icehockey": "Hockey su ghiaccio",
    "rollerhockey": "Hockey su rotelle",
    "badminton": "Badminton",
    "baseball": "Baseball",
    "basketball": "Pallacanestro",
    "bowling": "Bowling",
    "cricket": "Cricket",
    "floorball": "Floorball",
    "futsal": "Futsal",
    "gymnastics": "Ginnastica",
    "handball": "Palla a mano",
    "lacrosse": "Lacrosse",
    "netball": "Netball",
    "other": "Altro",
    "rugby": "Rugby",
    "snooker": "Biliardo",
    "soccer": "Calcio",
    "softball": "Softball",
    "swimming": "Nuoto",
    "tennis": "Tennis",
    "volleyball": "Pallavolo",
    "waterpolo": "Pallanuoto",
    "wrestling": "Lotta",
    "football": "Calcio"
  },
  "baseFooter": {
    "rights": "Tutti i diritti riservati"
  },
  "footer_links": {
    "about_us": "Di",
    "faq": "FAQ",
    "privacy_policy": "Privacy",
    "terms_of_service": "Termini",
    "custom": "{custom}"
  },
  "countries": {
    "AD": "Andorra",
    "AE": "Emirati Arabi Uniti",
    "AF": "Afghanistan",
    "AG": "Antigua e Barbuda",
    "AI": "Anguilla",
    "AL": "Albania",
    "AM": "Armenia",
    "AO": "Angola",
    "AR": "Argentina",
    "AS": "Samoa americane",
    "AT": "Austria",
    "AU": "Australia",
    "AW": "Aruba",
    "AZ": "Azerbaigian",
    "BA": "Bosnia Erzegovina",
    "BB": "Barbados",
    "BD": "Bangladesh",
    "BE": "Belgio",
    "BF": "Burkina Faso",
    "BG": "Bulgaria",
    "BH": "Bahrein",
    "BI": "Burundi",
    "BJ": "Benin",
    "BM": "Bermuda",
    "BO": "Bolivia",
    "BR": "Brasile",
    "BS": "Bahamas",
    "BT": "Bhutan",
    "BW": "Botswana",
    "BY": "Bielorussia",
    "BZ": "Belize",
    "CA": "Canada",
    "CF": "Repubblica Centrafricana",
    "CG": "Congo",
    "CH": "Svizzera",
    "CK": "Isole Cook",
    "CL": "Chile",
    "CM": "Camerun",
    "CN": "Cina",
    "CO": "Colombia",
    "CR": "Costa Rica",
    "CU": "Cuba",
    "CX": "Isola di Natale",
    "CY": "Cipro",
    "DE": "Germania",
    "DJ": "Gibuti",
    "DK": "Danimarca",
    "DM": "Dominica",
    "DO": "Repubblica Dominicana",
    "DZ": "Algeria",
    "EC": "Ecuador",
    "EE": "Estonia",
    "EG": "Egitto",
    "ER": "Eritrea",
    "ES": "Spagna",
    "ET": "Etiopia",
    "FI": "Finlandia",
    "FJ": "Figi",
    "FK": "Isole Falkland",
    "FM": "Micronesia",
    "FO": "Isole Faroe",
    "FR": "Francia",
    "GA": "Gabon",
    "GD": "Granata",
    "GE": "Georgia",
    "GF": "Guiana francese",
    "GH": "Ghana",
    "GI": "Gibilterra",
    "GL": "Groenlandia",
    "GM": "Gambia",
    "GN": "Guinea",
    "GP": "Guadalupa",
    "GQ": "Guinea Equatoriale",
    "GR": "Grecia",
    "GS": "Georgia del Sud e Isole Sandwich meridionali",
    "GT": "Guatemala",
    "GU": "Guam",
    "GW": "Guinea Bissau",
    "GY": "Guyana",
    "HN": "Honduras",
    "HR": "Croazia",
    "HT": "Haiti",
    "HU": "Ungheria",
    "ID": "Indonesia",
    "IE": "Irlanda",
    "IL": "Israele",
    "IN": "India",
    "IO": "Territorio britannico dell'Oceano Indiano",
    "IQ": "Iraq",
    "IR": "Iran",
    "IS": "Islanda",
    "IT": "Italia",
    "JM": "Giamaica",
    "JO": "Giordania",
    "JP": "Giappone",
    "KE": "Kenia",
    "KG": "Kirghizistan",
    "KH": "Cambogia",
    "KI": "Kiribati",
    "KM": "Comore",
    "KW": "Kuwait",
    "KY": "Isole Cayman",
    "KZ": "Kazakistan",
    "LB": "Libano",
    "LI": "Liechtenstein",
    "LK": "Sri Lanka",
    "LR": "Liberia",
    "LS": "Lesoto",
    "LT": "Lituania",
    "LU": "Lussemburgo",
    "LV": "Lettonia",
    "LY": "Libia",
    "MA": "Marocco",
    "MC": "Monaco",
    "MD": "Moldavia",
    "ME": "Montenegro",
    "MG": "Madagascar",
    "MH": "Isole Marshall",
    "ML": "Mali",
    "MM": "Birmania",
    "MN": "Mongolia",
    "MP": "Isole Marianne settentrionali",
    "MQ": "Martinica",
    "MR": "Mauritania",
    "MS": "Montserrat",
    "MT": "Malta",
    "MU": "Maurizio",
    "MV": "Maldive",
    "MW": "Malawi",
    "MX": "Messico",
    "MY": "Malaysia",
    "NA": "Namibia",
    "NC": "Nuova Caledonia",
    "NE": "Niger",
    "NF": "Isola Norfolk",
    "NG": "Nigeria",
    "NI": "Nicaragua",
    "NL": "Olanda",
    "NO": "Norvegia",
    "NP": "Nepal",
    "NR": "Nauru",
    "NU": "Niue",
    "NZ": "Nuova Zelanda",
    "OM": "Oman",
    "PA": "Panama",
    "PE": "Perù",
    "PF": "Polinesia francese",
    "PG": "Papua Nuova Guinea",
    "PH": "Filippine",
    "PK": "Pakistan",
    "PL": "Polonia",
    "PR": "Portorico",
    "PT": "Portogallo",
    "PW": "Palau",
    "PY": "Paraguay",
    "QA": "Qatar",
    "RO": "Romania",
    "RS": "Serbia",
    "RW": "Ruanda",
    "SA": "Arabia Saudita",
    "SB": "Isole Salomone",
    "SC": "Seychelles",
    "SD": "Sudan",
    "SE": "Svezia",
    "SG": "Singapore",
    "SI": "Slovenia",
    "SK": "Slovacchia",
    "SL": "Sierra Leone",
    "SM": "San Marino",
    "SN": "Senegal",
    "SV": "El Salvador",
    "TC": "Isole Turks e Caicos",
    "TD": "Chad",
    "TG": "Andare",
    "TH": "Tailandia",
    "TJ": "Tagikistan",
    "TK": "Tokelau",
    "TM": "Turkmenistan",
    "TN": "Tunisia",
    "TO": "Tonga",
    "TR": "Tacchino",
    "TT": "Trinidad e Tobago",
    "TV": "Tuvalù",
    "TW": "Taiwan",
    "UA": "Ucraina",
    "UG": "Uganda",
    "UY": "Uruguay",
    "UZ": "Uzbekistan",
    "VE": "Venezuela",
    "VU": "Vanuatu",
    "WF": "Wallis e Futuna",
    "WS": "Samoa",
    "YE": "Yemen",
    "YT": "Mayotte",
    "ZA": "Sud Africa",
    "ZM": "Zambia",
    "ZW": "Zimbabwe",
    "BN": "Brunei",
    "CV": "capo Verde",
    "CW": "Curacao",
    "HK": "RAS di Hong Kong Cina",
    "KP": "Corea del nord",
    "KR": "Corea del Sud",
    "LA": "Laos",
    "MO": "RAS di Macao in Cina",
    "PS": "Territorio Palestinese",
    "RE": "Riunione",
    "RU": "Russia",
    "SY": "Siria",
    "TZ": "Tanzania",
    "GB": "Regno Unito",
    "US": "stati Uniti",
    "VG": "Isole Vergini Britanniche",
    "VI": "Isole Vergini americane",
    "VN": "Vietnam",
    "CD": "Congo, dem.\nRep. di (Zaire)",
    "AX": "Isole Aland",
    "BQ": "Caraibi Paesi Bassi",
    "EA": "Ceuta e Melilla",
    "EH": "Sahara occidentale",
    "GG": "Guernsey",
    "IC": "isole Canarie",
    "IM": "Isola di Man",
    "JE": "Maglia",
    "MZ": "Mozambico",
    "PN": "Isole Pitcairn",
    "SO": "Somalia",
    "SS": "Sudan del Sud",
    "SX": "Sint Maarten",
    "TA": "Tristan da Cunha",
    "TF": "Territori della Francia del sud",
    "VA": "Città del Vaticano",
    "XK": "Kosovo",
    "AC": "Isola dell'Ascensione",
    "CZ": "Cechia",
    "CC": "Isole Cocos (Keeling).",
    "TL": "Timor Est",
    "CI": "Costa d'Avorio",
    "MK": "Macedonia del Nord",
    "SR": "Suriname",
    "SZ": "Swaziland",
    "DG": "Diego Garcia"
  },
  "errors": {
    "expired_session_error": "La tua sessione è scaduta. \nPer favore esegui l'accesso di nuovo.",
    "vod_files_not_found": "Ops! \nFile video non trovati",
    "vod_playback_failed": "Ops! \nRiproduzione non riuscita durante lo streaming VOD",
    "could_not_find_event": "Spiacenti, non siamo riusciti a trovare l'evento che stai cercando.",
    "could_not_find_page": "Siamo spiacenti, non siamo riusciti a trovare la pagina che stai cercando.",
    "could_not_find_team": "Spiacenti, non siamo riusciti a trovare la squadra che stai cercando.",
    "event_not_found": "Evento non trovato",
    "page_not_found": "pagina non trovata",
    "team_not_found": "Squadra non trovata",
    "could_not_find_results": "Spiacenti, non siamo riusciti a trovare alcun risultato per questa ricerca.",
    "ERR400CL11": "Impossibile creare la clip",
    "ERR400CM11": "Pagamento fallito",
    "ERR400CM12": "Effettua ordine non riuscito",
    "ERR400CM13": "Acquisizione pagamento non riuscita",
    "ERR400CM14": "Il salvataggio delle informazioni sul commerciante dell'abbonamento non è riuscito",
    "ERR400CM15": "Controlla i dati della tua carta di credito",
    "ERR400CM16": "Valore piattaforma fornito non valido",
    "ERR400GN00": "Brutta richiesta",
    "ERR400GN11": "ID fornito non valido",
    "ERR400GN12": "Campi non aggiornabili forniti",
    "ERR400ID11": "Identità fornita non valida",
    "ERR400ID12": "Elemento di identità fornito non valido",
    "ERR400ID13": "L'identità non può essere aggiunta all'elenco dei club",
    "ERR400ID14": "Coppia di identità e elemento di identità fornita non valida",
    "ERR400TG11": "Impossibile creare il tag",
    "ERR400UR11": "Il nome e il cognome del profilo sono obbligatori",
    "ERR400UR12": "L'e-mail è obbligatoria",
    "ERR400UR13": "L'utente ha già una carta di credito memorizzata",
    "ERR400UR14": "Completa i campi nome, cognome ed e-mail del tuo profilo prima di salvare una carta di credito",
    "ERR400UR17": "L'utente non ha una carta di credito da modificare",
    "ERR400UR18": "L'abbonamento è già annullato",
    "ERR400UR19": "L'abbonamento non è attivo",
    "ERR400UR20": "Nome utente e password sono obbligatori",
    "ERR400UR21": "Conto non attivo",
    "ERR401AT11": "Il token è richiesto",
    "ERR401AT12": "Token di reimpostazione della password non valido",
    "ERR401AT13": "Token di aggiornamento non valido",
    "ERR401AT14": "Emittente soggetto sconosciuto",
    "ERR401AT15": "Tipo di token dell'oggetto non supportato",
    "ERR401AT16": "Gettone non valido",
    "ERR401AT17": "Token scaduto",
    "ERR401AT18": "Impossibile ottenere un'e-mail dal token",
    "ERR401AT19": "Utente non trovato",
    "ERR401GN00": "Non autorizzato",
    "ERR401UR11": "Il nome utente o la password sono errati. \nControlla le tue credenziali.",
    "ERR402GN00": "Pagamento richiesto",
    "ERR403GN00": "Vietato",
    "ERR403TG11": "L'utente non può aggiornare i tag globali",
    "ERR403TG12": "L'utente non può creare tag globali",
    "ERR403TG13": "L'utente non può eliminare i tag che non sono stati creati da uno",
    "ERR403TG14": "L'utente non può eliminare i tag globali",
    "ERR404CM11": "Impossibile ottenere abbonamenti",
    "ERR404CM12": "Codice di accesso non trovato",
    "ERR404EV11": "Impossibile ottenere informazioni sull'evento",
    "ERR404EV12": "Evento o VOD non trovato",
    "ERR404GN00": "Non trovato",
    "ERR404ID00": "Identità non trovata",
    "ERR404ID11": "Impossibile recuperare l'identità",
    "ERR404PR00": "Prodotto non trovato",
    "ERR404PR11": "Impossibile ottenere informazioni ppv",
    "ERR404TG00": "Etichetta non trovata",
    "ERR404TM00": "Squadra non trovata",
    "ERR404UR00": "Utente non trovato",
    "ERR404UR11": "Ordine non trovato",
    "ERR404UR12": "Abbonamento non trovato",
    "ERR404UR13": "l'utente non esiste",
    "ERR404UR14": "Il profilo non esiste",
    "ERR404UR15": "Carta non trovata per questo utente",
    "ERR404UR16": "Video non trovato nella watchlist",
    "ERR404VD11": "Impossibile recuperare Vod",
    "ERR409CM11": "Codice di accesso già in uso",
    "ERR409GN00": "Conflitto",
    "ERR409PR12": "Prodotto già acquistato",
    "ERR409UR11": "Il club è già stato aggiunto",
    "ERR409UR12": "Utente già registrato",
    "ERR422GN00": "Contenuto non elaborabile",
    "ERR451GN00": "Non disponibile per motivi legali",
    "ERR500GN00": "Errore interno del server",
    "ERR500NT12": "L'invio dell'e-mail di reimpostazione della password non è riuscito",
    "ERR501GN00": "Non implementato",
    "ERR502GN00": "Cattivo gateway",
    "ERR503GN00": "Servizio non disponibile",
    "ERR504GN00": "Timeout del gateway",
    "could_not_find_league": "Spiacenti, non siamo riusciti a trovare il campionato che stai cercando.",
    "default_error_message": "Si è verificato un errore del server. \nPer favore riprova più tardi",
    "device_limit_exceeded": "Limite dispositivo superato!",
    "league_not_found": "Lega non trovata",
    "vod_corrupted_files": "Non è stato possibile caricare il video",
    "passwords_dont_match": "Le password non corrispondono",
    "incomplete_cvc": "Il codice di sicurezza della tua carta è incompleto!",
    "incomplete_expiry": "La data di scadenza della tua carta è incompleta!",
    "incomplete_number": "Il tuo numero di carta è incompleto!",
    "invalid_expiry_month_past": "L'anno di scadenza della tua carta è nel passato!",
    "invalid_expiry_year": "L'anno di scadenza della tua carta non è valido!",
    "invalid_expiry_year_past": "L'anno di scadenza della tua carta è nel passato!",
    "invalid_number": "Il tuo numero di carta non è valido!",
    "popup_closed": "Il popup è stato chiuso",
    "login_failed": "Accesso non riuscito",
    "ERR400UR24": "La vecchia password è sbagliata. \nControlla le tue credenziali",
    "live_stream_error_message": "Ops! \nSembra che ci sia un po' di ritardo. \nIl nostro team sta lavorando attivamente per risolvere il problema il più rapidamente possibile. \nGrazie per la pazienza!"
  },
  "tags": {
    "baseball_bottom_1st": "inferiore 1°",
    "baseball_bottom_2nd": "inferiore 2°",
    "baseball_double": "Doppio",
    "baseball_double_play": "doppio gioco",
    "baseball_fly_out": "volare fuori",
    "baseball_ground_out": "messa a terra",
    "baseball_hit": "colpo",
    "baseball_home_run": "fuori campo",
    "baseball_popout": "saltare fuori",
    "baseball_run": "correre",
    "baseball_single": "separare",
    "baseball_stolen_base": "basamento rubato",
    "baseball_strikeout": "strikeout",
    "baseball_substitution": "sostituzione",
    "baseball_top_1st": "superiore 1°",
    "baseball_top_2nd": "superiore 2°",
    "baseball_triple_play": "triplo gioco",
    "baseball_tripple": "triplicare",
    "baseball_walk": "camminare",
    "basketball_alley_oop": "vicolo ops",
    "basketball_assist": "assistere",
    "basketball_big_play": "grande gioco",
    "basketball_block": "bloccare",
    "basketball_dunk": "schiacciata",
    "basketball_momentym": "quantità di moto",
    "basketball_money_time": "tempo di denaro",
    "basketball_substitution": "sostituzione",
    "basketball_three_point": "tre punti",
    "beach_volleyball_dig": "scavare",
    "beach_volleyball_double": "Doppio",
    "beach_volleyball_free_ball": "palla libera",
    "beach_volleyball_match_point": "match point",
    "beach_volleyball_mishit": "mishit",
    "beach_volleyball_serve": "servire",
    "beach_volleyball_set_point": "punto stabilito",
    "beach_volleyball_shank": "gambo",
    "beach_volleyball_side_out": "lato fuori",
    "beach_volleyball_spike": "arpione",
    "beach_volleyball_substitution": "sostituzione",
    "field_ball_loss": "perdita di palla",
    "field_ball_possesion": "possesso palla",
    "field_circle_penetration": "penetrazione circolare",
    "field_hockey_4v4": "4v4",
    "field_hockey_big_hit": "grande successo",
    "field_hockey_fight": "combattimento",
    "field_hockey_goal": "obiettivo",
    "field_hockey_other": "altro",
    "field_hockey_power_play": "gioco di potere",
    "field_hockey_save": "salva",
    "field_hockey_shot": "sparo",
    "field_hockey_substitution": "sostituzione",
    "field_interception": "intercettazione",
    "field_penalty_corner": "calcio d'angolo",
    "field_press_fp_hc": "premere",
    "field_time_penalty": "penalità di tempo",
    "field_turn_over": "turnover",
    "football_big_catch": "grande cattura",
    "football_big_hit": "grande successo",
    "football_big_run": "grande corsa",
    "football_field_goal": "obiettivo sul campo",
    "football_kick_return": "ritorno di calcio",
    "football_kickoff": "calcio d'inizio",
    "football_punt_return": "ritorno al punt",
    "football_substitution": "sostituzione",
    "football_touch_down": "atterrare",
    "futsal_corner_kick": "calcio d'angolo",
    "futsal_foul": "ripugnante",
    "futsal_free_kick": "calcio libero",
    "futsal_goal": "obiettivo",
    "futsal_header": "intestazione",
    "futsal_penalty": "pena",
    "futsal_redcard": "cartellino rosso",
    "futsal_save": "salva",
    "futsal_shot": "sparo",
    "futsal_shot_on_target": "tiro in porta",
    "futsal_substitution": "sostituzione",
    "futsal_yellowcard": "Carta gialla",
    "general_bad": "Cattivo",
    "general_drill": "trapano",
    "general_good": "Bene",
    "gymnastic_wow": "Oh",
    "handball_foul": "ripugnante",
    "handball_goal": "obiettivo",
    "handball_penalty_shot": "tiro di rigore",
    "handball_red_card": "cartellino rosso",
    "handball_save": "salva",
    "handball_shot_on_goal": "tiro in porta",
    "handball_substitution": "sostituzione",
    "handball_suspension": "sospensione",
    "handball_yellow_card": "Carta gialla",
    "hockey_4v4": "4v4",
    "hockey_big_hit": "grande successo",
    "hockey_fight": "combattimento",
    "hockey_goal": "obiettivo",
    "hockey_power_play": "gioco di potere",
    "hockey_save": "salva",
    "hockey_shot": "sparo",
    "hockey_substitution": "sostituzione",
    "lacrosse_clear": "chiaro",
    "lacrosse_face_off": "faccia a faccia",
    "lacrosse_goal": "obiettivo",
    "lacrosse_ground_ball": "palla a terra",
    "lacrosse_man_up": "uomo su",
    "lacrosse_penalty": "pena",
    "lacrosse_save": "salva",
    "lacrosse_shot": "sparo",
    "lacrosse_substitution": "sostituzione",
    "other_wow": "altro wow",
    "roller_ball_loss": "perdita di palla",
    "roller_ball_possesion": "possesso palla",
    "roller_circle_penetration": "penetrazione circolare",
    "roller_hockey_4v4": "4v4",
    "roller_hockey_big_hit": "grande successo",
    "roller_hockey_fight": "combattimento",
    "roller_hockey_goal": "obiettivo",
    "roller_hockey_other": "altro",
    "roller_hockey_power_play": "gioco di potere",
    "roller_hockey_save": "salva",
    "roller_hockey_shot": "sparo",
    "roller_hockey_substitution": "sostituzione",
    "roller_interception": "intercettazione",
    "roller_penalty_corner": "angolo",
    "roller_press_fp_hc": "premere",
    "roller_time_penalty": "penalità di tempo",
    "roller_turn_over": "turnover",
    "rugby_break_down": "guasto",
    "rugby_conversion": "conversione",
    "rugby_drop_goal": "obiettivo di caduta",
    "rugby_garry_owen": "garry owen",
    "rugby_grubber": "estirpatore",
    "rugby_knock_on": "bussare",
    "rugby_maul": "maul",
    "rugby_penalty_try": "meta di rigore",
    "rugby_substitution": "sostituzione",
    "soccer_corner_kick": "calcio d'angolo",
    "soccer_foul": "ripugnante",
    "soccer_free_kick": "calcio libero",
    "soccer_goal": "obiettivo",
    "soccer_header": "intestazione",
    "soccer_penalty": "pena",
    "soccer_redcard": "cartellino rosso",
    "soccer_save": "salva",
    "soccer_shot": "sparo",
    "soccer_shot_on_target": "tiro in porta",
    "soccer_substitution": "sostituzione",
    "soccer_yellowcard": "Carta gialla",
    "volleyball_dig": "scavare",
    "volleyball_double": "Doppio",
    "volleyball_free_ball": "palla libera",
    "volleyball_match_point": "match point",
    "volleyball_mishit": "mishit",
    "volleyball_serve": "servire",
    "volleyball_set_point": "punto stabilito",
    "volleyball_shank": "gambo",
    "volleyball_side_out": "lato fuori",
    "volleyball_spike": "arpione",
    "volleyball_substitution": "sostituzione",
    "wrestling_wow": "Oh"
  }
}
